<template>
  <div
    data-el="product-search-item-template"
    class="flex items-center border-b border-b-blue-450 py-2.5"
  >
    <slot
      name="productImage"
    >
      <Skeletor
        v-if="shouldShowGlobalPlaceholders"
        class="mr-7 flex size-20 shrink-0 items-center justify-center"
        :height="100"
      />
    </slot>

    <div class="flex grow flex-wrap md:flex-nowrap">
      <div class="grow">
        <div class="flex flex-1 flex-col text-primary">
          <slot
            name="productName"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders"
              class="mx-auto aspect-product-image h-36 self-start overflow-hidden  md:!mx-auto md:w-full md:shrink-0"
              :height="100"
            />
          </slot>
        </div>

        <div class="mb-2 flex flex-wrap gap-x-5 gap-y-1 border-b border-blue-450 py-1 pb-3 text-xs text-grey md:flex-nowrap md:border-b-0 md:pb-0">
          <slot
            name="productSku"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders"
              :width="100"
            />
          </slot>

          <slot
            name="productBarcode"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders"
              :width="100"
            />
          </slot>
          <slot
            name="customerProductCode"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders || showCustomerDataPlaceholders"
              :height="16"
              :width="118"
            />
          </slot>
        </div>

        <div
          class="flex flex-wrap border-b border-blue-450 pb-2.5 text-blue md:border-b-0 md:pb-0"
        >
          <slot
            name="stockQuantityLabel"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders"
              :height="18"
              :width="146"
            />
          </slot>

          <slot
            name="availabilityLabel"
          >
            <Skeletor
              v-if="shouldShowGlobalPlaceholders || showCustomerDataPlaceholders"
              :height="18"
              :width="146"
            />
          </slot>
        </div>
      </div>

      <div
        class="flex w-full shrink-0 items-center justify-between pt-3 text-blue md:block md:w-32 md:pl-5 md:pt-0 md:text-right"
      >
        <slot
          name="prices"
        >
          <Skeletor
            v-if="shouldShowGlobalPlaceholders || showCustomerDataPlaceholders"
            :height="36"
            :width="129"
            class="mb-2"
          />
        </slot>

        <slot
          name="addToCartButton"
        >
          <Skeletor
            v-if="shouldShowGlobalPlaceholders || showCustomerDataPlaceholders"
            pill
            :height="44"
            :width="110"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, shallowRef, useSlots } from 'vue'
import { Skeletor } from 'vue-skeletor'

const props = withDefaults(defineProps<{
  showGlobalPlaceholders?: boolean
  showCustomerDataPlaceholders?: boolean
}>(), {
  showGlobalPlaceholders: false,
  showCustomerDataPlaceholders: false,
})

const slots = useSlots()

const el = shallowRef<HTMLDivElement | null>(null)

const shouldShowGlobalPlaceholders = computed((): boolean => !Object.keys(slots).length || props.showGlobalPlaceholders)

defineExpose({
  el,
})
</script>

<style lang="postcss">
div[data-el='product-search-item-template'] {
  @apply flex-col;
  @media (min-width: 360px) {
    @apply flex-row;
  }
}
</style>
